@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap");

body {
  font-family: open sans, helveticaneue, helvetica neue, Helvetica, Arial,
    sans-serif;
}

ol.nested-counter-list {
  counter-reset: item;
}
ol.nested-counter-list li {
  display: block;
  margin-top: 16px;
  margin-bottom: 16px;
}
ol.nested-counter-list li::before {
  content: counters(item, ".") ". ";
  counter-increment: item;
  font-weight: bold;
}
ol.nested-counter-list ol {
  counter-reset: item;
}
ol.nested-counter-list ol > li {
  display: block;
}
ol.nested-counter-list ol > li::before {
  content: counters(item, ".") " ";
  counter-increment: item;
  font-weight: bold;
}

select:invalid,
select option[value=""] {
  color: #a0aec0;
}

.nowrap br {
  display: none;
}

.swiper-button-prev,
.swiper-button-next {
  color: #c1c1c1;
}

@keyframes fade-move-down {
  0% {
    transform: translate(0, -20px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 20px);
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
